import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const headingWarningStyles = {
  color: "dodgerblue",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

// data
const links = [
  {
    text: "Webesite coming soon",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  }
]

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Kelela Financial</title>
      <h2 style={headingAccentStyles}>
        Welcome to Kelela Financial!
        <br/>
        <br/>
        <br/>
        Looking for Financial Solutions that Work for You and Your Future?
      </h2>

      <div>
        <br/>
        <br/>
        <a href="https://calendly.com/emebet" target="_blank"
           style={linkStyle}>
          Schedule a slot in my calendar</a>
        <p style={paragraphStyles}>
          Or request a free consultation and/or financial analysis below.
        </p>
      </div>

      <div style={headingAccentStyles}>
        <h4> Contact Detail</h4>
        <p style={descriptionStyle}>832-334-9200</p>
        <p style={descriptionStyle}>kelelafinancial@gmail.com</p>
      </div>

      <h1>
        <p style={headingWarningStyles}>Full website coming soon</p>
      </h1>

    </main>
  )
}


export default IndexPage
